import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	groups     : [],
	group      : {},
	page       : 1,
	totalPages : 1,
	filters    : [

	],
};

const GroupsSlice = createSlice({
	name     : "groups",
	initialState,
	reducers : {
		setGroupsData : (state, action) => {
			state.groups = action.payload.docs;
			state.page = action.payload.updatedPage;
			state.totalPages = action.payload.totalPages;
		},
		addGroup : (state, action) => {
			state.groups.push(action.payload);
		},
		deleteGroup : (state, action) => {
			const newGroups = state.groups?.filter(group => group._id !== action.payload);
			state.groups = newGroups;
		},
		editGroup : (state, action) => {
			const findIndexGroup = state.groups.findIndex(group => group._id === action.payload.groupId);
			const newDataSeted = {
				_id   : action.payload.groupId,
				name  : action.payload.groupData.name,
				color : action.payload.groupData.color,
			};
			state.groups[findIndexGroup] = newDataSeted;
		},
	},
});

export default GroupsSlice;
