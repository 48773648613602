import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	meansComplaints : [],
	meanComplaint   : {},
	page            : 1,
	totalPages      : 1,
};

const MeansComplaintsSlice = createSlice({
	name     : "meansComplaintsSlice",
	initialState,
	reducers : {
		setMeansComplaintsData : (state, action) => {
			state.meansComplaints = action.payload.docs;
			state.page = action.payload.updatedPage;
			state.totalPages = action.payload.totalPages;
		},
		addMeansComplaints : (state, action) => {
			state.meansComplaints.push(action?.payload?.data);
		},
		deleteMeansComplaints : (state, action) => {
			const newMeansComplaintsData = state.meansComplaints.filter(item => item._id !== action.payload);
			state.meansComplaints = newMeansComplaintsData;
		},
	},
});

export default MeansComplaintsSlice;
