import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	AddOpen      : false,
	OtherAddOpen : false,
	OpenFilters  : false,
};

const ModalSlice = createSlice({
	name     : "addModal",
	initialState,
	reducers : {
		setOpenAddModal : (state, action) => {
			state.AddOpen = action.payload;
		},
		setOpenOtherAddModal : (state, action) => {
			state.OtherAddOpen = action.payload;
		},
		setOpenFilters : (state, action) => {
			state.OpenFilters = action.payload;
		},
	},
});

export default ModalSlice;
