import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	files : [],
};

const FilesSlice = createSlice({
	name     : "files",
	initialState,
	reducers : {
		setFilesData : (state, action) => {
			state.files.push(action.payload.files);
		},
	},
});

export default FilesSlice;
