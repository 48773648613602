import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	companies  : [],
	companie   : {},
	page       : 1,
	totalPages : 1,
};

const CompaniesSlice = createSlice({
	name     : "companies",
	initialState,
	reducers : {
		setCompaniesData : (state, action) => {
			state.companies = action.payload.docs;
			state.page = action.payload.updatedPage;
			state.totalPages = action.payload.totalPages;
		},
		addCompanie : (state, action) => {
			state.companies.push(action?.payload?.data);
		},
		deleteCompanie : (state, action) => {
			const newCompanieData = state.companies.filter(item => item._id !== action.payload);
			state.companies = newCompanieData;
		},
	},
});

export default CompaniesSlice;
