import { connect }  from "react-redux";
import { Redirect } from "react-router-dom";

const SessionFreeRoute = ({
	loggedIn,
	component : Component,
	render    : RenderComponent,
	...rest
}) => {
	if (loggedIn) return <Redirect to="/app/complaints" />;

	return RenderComponent
		? <RenderComponent {...rest} />
		: <Component {...rest} />;
};

const mapStateToProps = ({ _authSlice }) => ({
	loggedIn : _authSlice?.loggedIn ?? false,
});

export default connect(mapStateToProps)(SessionFreeRoute);
