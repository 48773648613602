import PropTypes              from "prop-types";
import { Button }             from "reactstrap";
import { isValidElementType } from "react-is";
import { toast }              from "react-toastify";
import { X }                  from "react-feather";

const ToasterComponent = ({ icon : Icon, title, body }) => (
	<div className="custom-toaster">
		<div className="upper">
			<Icon size={16} />

			<span className="title">
				{title}
			</span>

			<Button.Ripple className='closeToaster' color='transparent'>
				<X size={16} />
			</Button.Ripple>
		</div>
		<div className="lower">
			<span className="text">
				{body}
			</span>
		</div>
	</div>
);

ToasterComponent.propTypes = {
	icon : (props, propName) => {
		if (props[propName] && !isValidElementType(props[propName])) {
			return new Error(
				"Invalid prop 'icon' supplied to 'ToasterComponent': the prop is not a valid React component"
			);
		}
	},
	title : PropTypes.string.isRequired,
	body  : PropTypes.string.isRequired,
};

/**
 * Custom implementation helper for displaying toasts
 *
 * @author Alcalá León Yael Mártin A. <yael.alcalla@gmail.com>
 */
const toaster = {
	info : props => toast.info(<ToasterComponent {...props} />, {
		position        : toast.POSITION.TOP_RIGHT,
		hideProgressBar : true,
	}),
};

export default toaster;
