import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	token            : "",
	loggedIn         : false,
	user             : {},
	changedPassword  : true,
	inRecoverCount   : false,
	stepRecoverCount : 1,
};

const AuthSlice = createSlice({
	name     : "auth",
	initialState,
	reducers : {
		setUserData : (state, action) => {
			state.token    = action.payload.token;
			state.user     = action.payload.user;
			state.loggedIn = true;
		},
		setNewDataUser : (state, action) => {
			state.user.role = action.payload.role;
			state.user.name = action.payload.name;
		},
		setToken        : (state, action) => void(state.token = action.payload),
		setEmail        : (state, action) => void(state.user.email = action.payload),
		setStepRecovery : (state, action) => {
			state.inRecoverCount = true;
			state.stepRecoverCount = action.payload;
		},
		clearUserData : state => initialState,
	},
});

export default AuthSlice;
