import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	configData : {},
};

const PreferencesSlice = createSlice({
	name     : "preferences",
	initialState,
	reducers : {
		setConfigData : (state, action) => {
			state.configData = action.payload.configData;
		},
		editPreferences : (state, action) => {
			state.configData = action.payload.data;
		},
	},
});

export default PreferencesSlice;
