import { Suspense, lazy } from "react";
import { render }         from "react-dom";
import { Provider }       from "react-redux";
import { PersistGate }    from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { ThemeContext }   from "./Helpers/utility/context/ThemeColors";
import Spinner            from "./@core/components/spinner/Fallback-spinner";
import "./@core/components/ripple-button";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@styles/react/libs/toastify/toastify.scss";
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./Resources/scss/style.scss";

// Import Own Components
import { persistor, Store } from "./Store";
import * as serviceWorker   from "./serviceWorker";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

render(
	<Provider store={Store}>
		<PersistGate loading={<Spinner />} persistor={persistor}>
			<Suspense fallback={<Spinner />}>
				<ThemeContext>
					<LazyApp />

					<ToastContainer newestOnTop />
				</ThemeContext>
			</Suspense>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);

if (process.env.NODE_ENV === "production") {
	serviceWorker.register();
} else {
	serviceWorker.unregister();
}
