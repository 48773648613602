const colors = [
	"#ef5350",
	"#ec407a",
	"#ab47bc",
	"#42a5f5",
	"#26a69a",
	"#66bb6a",
	"#ffca28",
	"#ff7043",
	"#8d6e63",
	"#78909c",
	"#7c4dff",
	"#2e7d32",
	"#01579b",
	"#fbc02d",
	"#616161",
];

export default colors;
