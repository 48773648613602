import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	folio             : "",
	complaints        : [],
	complaint         : {},
	complaintComments : [],
	filters           : {
		start_date : undefined,
		end_date   : undefined,
		mean_id    : {
			name : undefined,
			id   : undefined,
		},
		type_id : {
			name : undefined,
			id   : undefined,
		},
		company_id : {
			id   : undefined,
			name : undefined,
		},
		status      : undefined,
		criticality : undefined,
		archived    : false,
	},
	page       : 1,
	totalPages : 1,
};

const ComplaintsSlice = createSlice({
	name     : "complaintsSlice",
	initialState,
	reducers : {
		setFolio : (state, action) => {
			state.folio = action.payload.folio;
		},
		setFilters : (state, action) => {
			state.filters = {...state.filters, ...action.payload};
		},
		setComplaintsData : (state, action) => {
			state.complaints = action.payload.docs;
			state.page = action.payload.updatedPage;
			state.totalPages = action.payload.totalPages;
		},
		addComplaint : (state, action) => {
			const newData = {...action.payload.data};
			newData.mean = {
				icon : action.payload.dataMean.icon,
				name : action.payload.dataMean.label,
			};

			newData.type = {
				name : action.payload.dataType.label,
			};
			state.complaints.push(newData);
		},
		deleteComplaint : (state, action) => {
			const newComplaintData = state.complaints.filter(item => item._id !== action.payload);
			state.typeComplaints = newComplaintData;
		},
		setComplaintData : (state, action) => {
			state.complaint = action.payload.data;
		},
		setComplaintComments : (state, action) => {
			state.complaintComments = action.payload.data;
		},
		deleteCommentComplaint : (state, action) => {
			const newCommentData = state.complaintComments.filter(item => item._id !== action.payload);
			state.complaintComments = newCommentData;
		},
	},
});

export default ComplaintsSlice;
